import { i18n } from '@/plugins/i18n.js'

// Authentication
const Registration = () =>
  import('@/components/views/Main/Authentication/RegistrationV3/RegistrationV3.vue')
const RegisterSuccess = () =>
  import('@/components/views/Main/Authentication/RegistrationV3/RegistrationSuccess.vue')
const Login = () => import('@/components/views/Main/Authentication/LoginV3/LoginV3.vue')
const ActivateAccount = () =>
  import('@/components/views/Main/Authentication/LoginV3/ActivateAccount.vue')
const Logout = () => import('@/components/views/Main/Authentication/Logout/Logout.vue')
const ForgotPassword = () => import('@/components/views/ForgotPassword/ForgotPasswordV3.vue')
const VerifyEmail = () =>
  import('@/components/views/Main/Authentication/VerifyEmail/VerifyEmailV3.vue')
const ResetPassword = () => import('@/components/views/ResetPassword/ResetPassword.vue')

export default [
  {
    path: 'registration',
    name: 'Register',
    component: Registration,
    meta: {
      title: i18n.t('headful_title.registration'),
      bgType: 'primary',
      layoutType: 'boxes-overlay-narrow',
      redirectOnAuthentication: true,
      queryParamsToKeep: ['signed_out_kit_activation']
    }
  },
  {
    path: 'registration/success',
    name: 'RegisterSuccess',
    component: RegisterSuccess,
    meta: {
      title: i18n.t('headful_title.registration_success'),
      isLinksHidden: true,
      redirectOnAuthentication: true
    }
  },
  {
    path: 'login',
    name: 'Login',
    component: Login,
    meta: {
      title: i18n.t('headful_title.login'),
      bgType: 'primary',
      layoutType: 'boxes-overlay-narrow',
      redirectOnAuthentication: true,
      queryParamsToKeep: ['signed_out_kit_activation']
    }
  },
  {
    path: 'login/verify-email/:email',
    name: 'ActivateAccount',
    component: ActivateAccount,
    props: true,
    meta: {
      title: i18n.t('headful_title.verify_email'),
      isLinksHidden: true
    }
  },
  {
    path: 'logout',
    name: 'Logout',
    component: Logout,
    meta: {
      title: i18n.t('headful_title.logout')
    }
  },
  {
    path: 'forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: i18n.t('headful_title.forgot_password'),
      bgType: 'primary',
      layoutType: 'boxes-overlay-narrow',
      redirectOnAuthentication: true
    }
  },
  {
    path: 'verify-email/:activationKey',
    name: 'VerifyEmail',
    component: VerifyEmail,
    props: true,
    meta: {
      title: i18n.t('headful_title.verify_email'),
      bgType: 'primary',
      layoutType: 'boxes-overlay-narrow'
    }
  },
  {
    path: 'change-password/:uid/:token/',
    name: 'ResetPassword',
    component: ResetPassword,
    props: (route) => ({
      ...route.params,
      emailActivationToken: route.query.emailActivationToken
    }),
    meta: {
      title: i18n.t('headful_title.change_password'),
      bgType: 'primary',
      layoutType: 'boxes-overlay-narrow'
    }
  }
]
