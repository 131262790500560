// Kit registration flow
import { i18n } from '@/plugins/i18n.js'
import { store } from '@/store/index.js'

const KitRegistration = () => import('@/components/views/KitRegistration/KitRegistration.vue')
const KitSelection = () =>
  import('@/components/views/KitRegistration/KitSelectionV3/KitSelectionV3.vue')
const KitActivate = () =>
  import('@/components/views/KitRegistration/KitActivateV3/KitActivateV3.vue')
const HealthHistory = () =>
  import('@/components/views/KitRegistration/HealthHistory/HealthHistoryV3.vue')
const LdtFailed = () => import('@/components/views/KitRegistration/LDTFailed/LDTFailed.vue')
const GiveConsent = () => import('@/components/views/KitRegistration/GiveConsent/GiveConsentV3.vue')
const ProvideSample = () =>
  import('@/components/views/KitRegistration/ProvideSample/ProvideSampleV3.vue')

export default {
  path: 'kit-activation',
  component: KitRegistration,
  children: [
    {
      path: ':productType',
      name: 'KitSelection',
      component: KitSelection,
      props: true,
      meta: {
        title: i18n.t('headful_title.kit_selection'),
        requiresAuth: true,
        skipSessionPopup: true,
        isFooterHidden: true,
        bgType: 'blank',
        layoutType: 'custom-kitreg-1'
      }
    },
    {
      path: 'activate-kit/:productType/:productId?/:productStatusId?',
      name: 'KitActivate',
      component: KitActivate,
      props: true,
      meta: {
        title: i18n.t('headful_title.kit_activate'),
        requiresAuth: true,
        isFooterHidden: true,
        bgType: 'blank',
        layoutType: 'custom-kitreg-2'
      }
    },
    {
      path: 'answer-questionnaire/:productId/:collectionLocation/:kitId/:label/:productStatusId?',
      name: 'HealthHistory',
      component: HealthHistory,
      props: true,
      beforeEnter: async (to, from, next) => {
        // The consent can be upfront the questionnaire.
        // So even though external component might route directly to 'HealthHistory', the consent must be done first
        if (
          !store.getters.featureFlags.consentBeforeQuestionnairePatientKitActivate ||
          from.name === 'GiveConsent'
        ) {
          return next()
        }
        return next({ name: 'GiveConsent', params: { ...to.params }, query: { ...to.query } })
      },
      meta: {
        title: i18n.t('headful_title.health_history'),
        requiresAuth: true,
        isFooterHidden: true,
        bgType: 'blank',
        layoutType: 'custom-kitreg-3'
      }
    },
    {
      path: 'ldt-failed/:productId/:productStatusId',
      name: 'LdtFailed',
      component: LdtFailed,
      props: true,
      meta: {
        title: i18n.t('headful_title.ldt_failed'),
        requiresAuth: true,
        isFooterHidden: true,
        bgType: 'blank',
        layoutType: 'custom-kitreg-4'
      }
    },
    // Consenting can be BEFORE or AFTER the kit activation depending on the consent_before_questionnaire_patient_kit_activate FF
    {
      path: 'give-consent/:productId/:collectionLocation/:kitId/:label/:productStatusId?',
      name: 'GiveConsent',
      component: GiveConsent,
      props: true,
      meta: {
        title: i18n.t('headful_title.give_consent'),
        requiresAuth: true,
        isFooterHidden: true,
        bgType: 'blank',
        layoutType: 'custom-kitreg-5'
      }
    },
    {
      path: 'provide-sample/:productId/:productStatusId',
      name: 'ProvideSample',
      component: ProvideSample,
      props: true,
      meta: {
        title: i18n.t('headful_title.provide_sample'),
        requiresAuth: true,
        isFooterHidden: true,
        bgType: 'blank',
        layoutType: 'custom-kitreg-7'
      }
    }
  ],
  meta: { requiresAuth: true }
}
