import Vue from 'vue'
import moment from 'moment'
import { CONFIG } from '@/constants/config.js'
import { DateTime } from 'luxon'
import { keyBy } from 'lodash-es'
import states from '@/config/states_titlecase.js'
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { supportedLanguages } from '@/config/translations.js'
import { logToSentry, SENTRY_LOG_LEVEL } from '@/utils/logging.js'
import { i18n } from '@/plugins/i18n.js'
import { getConfig } from '@/use/useConfig.js'
import { RESULT_VIEWER } from '@/constants/product.js'
import { getWorkflowState } from '@/utils/productStatusUtils.js'
import {
  WORKFLOW_STATE_APPOINTMENT_SCHEDULED,
  WORKFLOW_STATE_KIT_ERROR,
  WORKFLOW_STATE_RESULT_READY
} from '@/constants/workflowStates.js'
import { errorHandler } from '@/utils/errorUtils.js'
import { displayPhone, uploadPhone } from '@/utils/profileUtils.js'
import { hasAcceptedMandatoryConsents } from '@/utils/consentUtils.js'
import { RESULT_APPROVED_STATES } from '@/constants/results.js'
import { isStateInTransitionHistory } from '@/utils/workflowUtils.js'

const scrollToTop = () => {
  scrollToElement(0, 0)
}

const scrollToBottom = () => {
  scrollToElement(0, document.body.scrollHeight)
}

const scrollToElement = (left, top, behavior = 'smooth') => {
  window.scrollTo({
    left,
    top,
    behavior
  })
}

const scrollToInvalid = (selector = '.font-bc--warning') => {
  Vue.nextTick(() => {
    if (window) {
      const elem = document.querySelector(selector)
      // Add check to ensure element exists before getting bounding client rect
      if (elem) {
        const domRect = elem?.getBoundingClientRect()
        window.scrollTo({
          left: 0,
          top: domRect.top + document.documentElement.scrollTop - 500,
          behavior: 'smooth'
        })
      }
    }
  })
}

const scrollToElemId = (id, yOffset = 0) => {
  const elem = document.getElementById(id)
  const yAxis = elem?.getBoundingClientRect().top + window.scrollY + yOffset
  scrollToElement(0, yAxis)
}

const getDateObj = (dateString, transToLocal = false) => {
  if (dateString) {
    let date = dateString
    if (transToLocal) {
      date = moment.utc(dateString).local().format('YYYY-MM-DDTHH:mm:ss.sssZ')
    }
    const dateObj = moment(date)
    return {
      year: dateObj.year(),
      month: dateObj.month(),
      // the date() returns the day of month
      day: dateObj.date(),
      hour: dateObj.hours(),
      min: dateObj.minute(),
      sec: dateObj.seconds()
    }
  }
}

const displayDate = (date, format = getConfig(CONFIG.DATE_TIME_FORMAT)?.default) => {
  if (date && date.year) {
    const newDate = new Date(
      date.year,
      date.month,
      date.day,
      date.hour ?? 0,
      date.min ?? 0,
      date.sec ?? 0
    )
    return moment(newDate).format(format)
  }
}

const getLocalTimezone = (format) => {
  return DateTime.local().toFormat(format)
}

const displayAddressCityStateZip = (address) => {
  if (address.city) {
    return address.city + ', ' + getStateAbbrev(address.state) + ', ' + address.zip
  }
}

const filterStates = (excludedStates = []) =>
  states.states.filter(
    (state) =>
      getConfig(CONFIG.FOOTER_EXCLUDED_STATES)?.indexOf(state.name) < 0 &&
      !excludedStates.includes(state.abbreviation)
  )

const stateLookUpByName = keyBy(states.states, 'name')

const getStateAbbrev = (stateLongName) => {
  // If no abbreviation is found, then the name is most likely already the abbreviation
  return stateLookUpByName[stateLongName]?.abbreviation ?? stateLongName
}

const stateLookUpByAbbrev = keyBy(states.states, 'abbreviation')

const getStateName = (stateAbbrev) => {
  if (stateAbbrev) {
    return stateLookUpByAbbrev[stateAbbrev]?.name ?? ''
  }

  return ''
}

const languageLookUpByAbbrev = keyBy(supportedLanguages, 'abbreviation')

const getLanguageName = (languageAbbrev) => {
  if (languageAbbrev) {
    return languageLookUpByAbbrev[languageAbbrev].name
  }
}

const languageLookUpByName = keyBy(supportedLanguages, 'name')

const getLanguageAbbrev = (languageName) => {
  if (languageName) {
    return languageLookUpByName[languageName].abbreviation
  }
}

const downloadObservation = (observationId, isDownloadForView = false) => {
  return IXLayerAPI.downloadPDF('observation', observationId, isDownloadForView)
}

const downloadRequisitionForm = (productStatusId) => {
  return IXLayerAPI.downloadPDF('requisition', productStatusId)
}

const downloadResults = (productStatusId, isDownloadForView = false) => {
  return IXLayerAPI.getLabResults(productStatusId).then((results) => {
    if (results.data.count) {
      return IXLayerAPI.downloadPDF(
        'result',
        results.data.results[results.data.results.length - 1].id,
        isDownloadForView
      )
    }
  })
}

const viewResultFirstTime = (productStatusId, product, router) => {
  const productId = product.id
  IXLayerAPI.viewedResult(productStatusId).then(() => {
    router
      .push({
        name: product.resultViewer === RESULT_VIEWER.PDF ? 'StaticResults' : 'Results',
        params: { productId, productStatusId }
      })
      .catch(() => {})
  })
}

const viewResult = async (product, status, router) => {
  const productStatusId = status.productStatusId
  const productId = product.id
  const hasPreResultConsent = product.flowData?.has_pre_result_consent
  const hasViewedResult = status.hasViewedResult

  if (!hasViewedResult) {
    await IXLayerAPI.viewedResult(productStatusId)
  }

  router
    .push({
      name:
        hasPreResultConsent && !hasViewedResult
          ? 'PreResultConsent'
          : product.resultViewer === RESULT_VIEWER.PDF
            ? 'StaticResults'
            : 'Results',
      params: { productId, productStatusId }
    })
    .catch(() => {})
}

const isGuardianMandatory = (dateOfBirth) => {
  const dob = moment([dateOfBirth.year, dateOfBirth.month, dateOfBirth.day])
  if (moment(dob).isValid()) {
    const userAge = moment().diff(dob, 'years')
    return userAge < getConfig(CONFIG.PROFILE_AGE_LIMIT_TO_NOT_HAVE_GUARDIAN)
  }
  return false
}

const isAllProfileFieldsComplete = (profile) => {
  if (!profile) {
    return false
  }
  let profileFields = [
    profile.first_name,
    profile.last_name,
    profile.date_of_birth,
    profile.gender,
    profile.phone,
    profile.address,
    profile.city,
    profile.state,
    profile.zip
  ]
  if (profile.date_of_birth) {
    const dateOfBirthObj = getDateObj(profile.date_of_birth)
    if (isGuardianMandatory(dateOfBirthObj)) {
      profileFields = [
        ...profileFields,
        profile.guardian_first_name,
        profile.guardian_last_name,
        profile.guardian_email,
        profile.guardian_phone,
        profile.guardian_address,
        profile.guardian_city,
        profile.guardian_state,
        profile.guardian_zip
      ]
    }
  }
  return profileFields.every((x) => !!x)
}

const hasProfileCompleted = (profile) => {
  return isAllProfileFieldsComplete(profile) && hasAcceptedMandatoryConsents(profile)
}

const getProductStatusDetails = (productStatus, store) => {
  if (productStatus) {
    const state = getWorkflowState(productStatus)
    const hasResultApproved = store.getters.useWorkflowStatusesEnabled
      ? RESULT_APPROVED_STATES.includes(state)
      : !!productStatus.result_approved_date

    const productStatusDetails = {
      orderId: productStatus.order_id,
      customData: productStatus.custom_data,
      dates: {
        created: productStatus.created_date,
        lastUpdated: productStatus.updated_date || productStatus.last_updated_date,
        kitShipped:
          productStatus.fulfillments?.length > 0
            ? productStatus.fulfillments[0].shipped_date
            : null,
        ldtSubmitted: productStatus.ldt_submitted_date,
        ldtApproved: productStatus.ldt_approved_date,
        onboardingCompleted: productStatus.onboarding_complete_date,
        resultRejected: productStatus.result_rejected_date,
        kitRegistered: productStatus.kit_registered_date,
        kitReceived: productStatus.kit_received_date,
        inAnalysis: productStatus.in_analysis_date,
        dataReady: productStatus.data_ready_date,
        resultReady: productStatus.result_ready_date,
        resultApproved: productStatus.result_approved_date,
        kitError: productStatus.kit_error_date,
        kitReshipped: productStatus.kit_reshipped_date,
        ldtRejected: productStatus.ldt_rejected_date,
        purchaseCancelled: productStatus.purchase_cancelled_date,
        deactivated: productStatus.deactivated_date
      },
      hasPurchased: () => !!productStatus.purchase_date,
      hasInsuranceSubmitted: () => !!productStatus.insurance_selected_date,
      hasProductStatus: () => !!productStatus.created_date,
      hasKitShipped: () =>
        productStatus.fulfillments?.length > 0 && !!productStatus.fulfillments[0].shipped_date,
      hasLdtApproved: () => !!productStatus.ldt_approved_date,
      hasOnboardingCompleted: () => !!productStatus.onboarding_complete_date,
      hasRegisteredKit: () => !!productStatus.kit_registered_date,
      hasResultReady: () =>
        store.getters.useWorkflowStatusesEnabled
          ? state === WORKFLOW_STATE_RESULT_READY
          : !!productStatus.result_ready_date,
      hasResultApproved: () => hasResultApproved,
      hasMobilePhlebotomyAppointment: () => state === WORKFLOW_STATE_APPOINTMENT_SCHEDULED,
      hasNoAppointmentScheduledYet: () =>
        !isStateInTransitionHistory(productStatus, WORKFLOW_STATE_APPOINTMENT_SCHEDULED),
      hasServiceCenterAppointment: () =>
        hasResultApproved &&
        !!productStatus.transition_history.findLast(
          (tr) => tr.to_state === WORKFLOW_STATE_APPOINTMENT_SCHEDULED
        ),
      hasViewedResult: () => !!productStatus.result_consent_agreed_date,
      hasAnsweredQuestionnaire: () => !!productStatus.questionnaire_response,
      hasConsentedToTest: () => !!productStatus.test_consent_agreed_date,
      hasConsentedToProduct: () => !!productStatus.result_consent_agreed_date,
      allCompleted: () =>
        productStatusDetails.hasViewedResult() &&
        productStatusDetails.hasRegisteredKit() &&
        productStatusDetails.hasAnsweredQuestionnaire() &&
        productStatusDetails.hasConsentedToTest(),
      errorCases: {
        hasKitError: () =>
          store.getters.featureFlags?.useWorkflowStatuses
            ? productStatus.is_recoverable !== null &&
              !productStatus.is_deactivated &&
              isStateInTransitionHistory(productStatus, WORKFLOW_STATE_KIT_ERROR)
            : !!productStatus.kit_error_date,
        hasKitReshipped: () => !!productStatus.kit_reshipped_date,
        hasLdtRejected: () => !!productStatus.ldt_rejected_date,
        hasPurchaseCancelled: () => !!productStatus.purchase_cancelled_date,
        hasResultRejected: () => !!productStatus.result_rejected_date,
        isDeactivated: () => productStatus.is_deactivated
      },
      vaccination: productStatus.vaccination
    }
    return productStatusDetails
  }
}

const getOrgProducts = (organization, productList) => {
  if (organization && productList) {
    return productList.filter((product) =>
      organization.products?.find((val) => val.id === product.id)
    )
  }
}

const hasProductStatus = (profileStatus) => {
  return Object.values(profileStatus?.hasProductStatus).some((i) => i)
}

const getPPPDocument = (label, orgId, prdId) => {
  if (label.includes('{orgID}')) {
    label = label.replace('{orgID}', `${orgId}`)
  }
  if (label.includes('{prdID}')) {
    label = label.replace('{prdID}', `${prdId}`)
  }

  return IXLayerAPI.getDocument(label)
}

const getNavBarMenuItems = (items, allowUserReg, router) => {
  if (!items || !items.length) return null
  return items.map((item) => {
    if (item.links) {
      return {
        links: item.links.map((link) => ({
          to: link.to,
          href: link.href,
          mailTo: link.mailTo,
          elmId: link.elmId,
          path: link.authUrlSuffix
            ? `${allowUserReg ? 'registration' : 'login'}${link.authUrlSuffix}`
            : null,
          phone: link.phone ? uploadPhone(link.phone) : null,
          text: link.phone ? displayPhone(link.phone) : link.text
        })),
        title: item.title ?? null
      }
    } else if (item.dropdownItems) {
      return {
        dropdownItems: item.dropdownItems.map((dropdownItem) => ({
          onClick: () =>
            dropdownItem.to ? router.push({ name: dropdownItem.to }).catch(() => {}) : null,
          color: dropdownItem.color,
          hasIcon: dropdownItem.hasIcon,
          href: dropdownItem.href,
          text: dropdownItem.text
        })),
        text: item.text
      }
    }

    return {}
  })
}

const waitForAsyncValidation = (form) => {
  return new Promise((resolve, reject) => {
    let countPoll = 0
    if (!form.$pending) {
      return resolve()
    }
    const poll = setInterval(() => {
      countPoll += 1
      if (!form.$pending) {
        clearInterval(poll)
        return resolve()
      }
      if (countPoll > 25) {
        clearInterval(poll)
        return reject(
          errorHandler({
            toastedMessage: i18n.t('error.service_not_available'),
            sentryErrMessage: 'ERROR IN ASYNC VALIDATION'
          })
        )
      }
    }, 200)
  })
}

const clearObject = (object) => {
  const clearObj = {}
  Object.keys(object).forEach((key) => {
    const val = object[key]
    if (typeof val !== 'object') {
      clearObj[key] = null
    } else {
      clearObj[key] = !val ? null : clearObject(val)
    }
  })
  return clearObj
}

const resetState = (initalState, state) => {
  const s = initalState()
  Object.keys(s).forEach((key) => {
    state[key] = s[key]
  })
}

const routePathLastSegment = (path) => {
  const routePathLastSegment = path.substring(path.lastIndexOf('/') + 1)
  return routePathLastSegment.replace(/-/g, '_')
}

const cleanCssThemeUrl = (themeVar) => {
  return themeVar?.match(/url\((.*)\)/)?.pop()
}

export default {
  scrollToTop,
  scrollToBottom,
  scrollToInvalid,
  scrollToElemId,
  getDateObj,
  displayDate,
  getLocalTimezone,
  displayAddressCityStateZip,
  filterStates,
  getStateAbbrev,
  getStateName,
  getLanguageName,
  getLanguageAbbrev,
  downloadObservation,
  downloadRequisitionForm,
  downloadResults,
  viewResultFirstTime,
  viewResult,
  isGuardianMandatory,
  hasProfileCompleted,
  isAllProfileFieldsComplete,
  getProductStatusDetails,
  getOrgProducts,
  hasProductStatus,
  getPPPDocument,
  getNavBarMenuItems,
  waitForAsyncValidation,
  clearObject,
  resetState,
  routePathLastSegment,
  cleanCssThemeUrl,
  logToSentry,
  SENTRY_LOG_LEVEL
}
