import { camelCase } from 'lodash-es'
import { defaultLanguage } from '@/config/translations.js'
import { DEFAULT_ORG_CONTENT_ID } from '@/constants/content.js'
import { WORKFLOW_STATES } from '@/constants/workflowStates.js'
import { parseTags } from '@/utils/arrayUtils.js'
import { errorHandler } from '@/utils/errorUtils.js'

const state = {
  workflow: []
}

export const FETCH_WORKFLOW_CONTENT = 'FETCH_WORKFLOW_CONTENT'
const SET_WORKFLOW_CONTENT = 'SET_WORKFLOW_CONTENT'

const getters = {
  cmsWorkflowContent: (state) => state.workflow
}

const mutations = {
  [SET_WORKFLOW_CONTENT]: (state, { defaultContent, orgSpecificContent }) => {
    const validWorkflowStates = Array.from(WORKFLOW_STATES.keys())

    const mergedContent = [...defaultContent, ...orgSpecificContent]
      .filter((item) => parseTags(item.meta?.tags).some((tag) => validWorkflowStates.includes(tag)))
      .filter(
        (item) =>
          item?.meta?.body?.pp &&
          (item.meta.body.pp.title ||
            item.meta.body.pp.headline ||
            item.meta.body.pp.text ||
            item.meta.body.pp.statuses ||
            item.meta.body.pp.instructionsButton)
      )
      .reduce((content, item) => {
        const meta = item.meta
        const parsedTags = parseTags(meta.tags)
        const tags = parsedTags
          .filter((tag) => !tag.startsWith('sku:'))
          .sort((a, z) => validWorkflowStates.indexOf(z) - validWorkflowStates.indexOf(a))
        const skus = parsedTags.filter((tag) => tag.startsWith('sku:'))
        const body = meta?.body?.pp
        const state = camelCase(tags[0])
        const key = [state, ...tags.splice(1)].join('|')

        const value = {
          title: body?.title ?? '',
          headline: body?.headline ?? '',
          text: body?.text ?? '',
          statuses: body?.statuses ?? {},
          instructionsButton: body?.instructionsButton || ''
        }

        content[key] = value
        skus.forEach((sku) => {
          const skuKey = [state, sku, ...tags.splice(1)].join('|')
          content[skuKey] = value
        })

        return content
      }, {})

    state.workflow = mergedContent
  }
}

const actions = {
  [FETCH_WORKFLOW_CONTENT]: async ({ commit, rootGetters }, { orgContentId, params } = {}) => {
    try {
      const defaultContentPromise = rootGetters.cmsApiInstance.get('workflow/', {
        params: {
          org_content_id: DEFAULT_ORG_CONTENT_ID,
          locale: defaultLanguage,
          live: true,
          ...params
        }
      })

      const orgSpecificContentPromise = rootGetters.cmsApiInstance.get('workflow/', {
        params: {
          org_content_id: orgContentId,
          locale: defaultLanguage,
          live: true,
          ...params
        }
      })

      const [defaultContent, orgSpecificContent] = await Promise.all([
        defaultContentPromise,
        orgSpecificContentPromise
      ])

      commit(SET_WORKFLOW_CONTENT, {
        defaultContent: defaultContent.data.items,
        orgSpecificContent: orgSpecificContent.data.items
      })
    } catch (error) {
      errorHandler({
        sentryErrMessage: `FAILED TO FETCH CMS WORKFLOW CONTENT: ${
          error.response?.data?.message || error
        }`
      })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
