<script>
import { mergeData } from 'vue-functional-data-merge'

export default {
  name: 'BaseBtn',

  functional: true,

  props: {
    tag: {
      type: String,
      default: 'button'
    },

    type: {
      type: String,
      default: 'button'
    },

    href: {
      type: String,
      default: ''
    },

    to: {
      type: [String, Object],
      default: ''
    },

    expandOnSmall: {
      type: Boolean,
      default: false
    },

    secondary: {
      type: Boolean,
      default: false
    },

    danger: {
      type: Boolean,
      default: false
    },

    success: {
      type: Boolean,
      default: false
    },

    squared: {
      type: Boolean,
      default: false
    },

    shrinkOnSmall: {
      type: Boolean,
      default: false
    },

    isIconOnly: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  render: function (createElement, { props, data, children }) {
    let tag = props.tag
    if (props.to) {
      tag = 'router-link'
    } else if (props.href) {
      tag = 'a'
    }

    const componentData = {
      props: {
        ...(props.to ? { to: props.to } : {})
      },
      attrs: {
        ...(props.href ? { href: props.href } : {}),
        ...(!(props.href || props.to) && props.tag === 'button' && props.type
          ? { type: props.type }
          : {})
      },
      class: {
        'base-btn': true,
        'base-btn--expand-on-small': props.expandOnSmall,
        'base-btn--secondary': props.secondary,
        'base-btn--danger': props.danger,
        'base-btn--success': props.success,
        'base-btn--squared': props.squared,
        'base-btn--icon-only': props.isIconOnly,
        'base-btn--shrink-on-small': props.shrinkOnSmall,
        'base-btn--disabled': props.isLoading
      }
    }

    return createElement(tag, mergeData(data, componentData), [
      children,
      props.isLoading
        ? createElement('b-spinner', {
            props: { small: true },
            class: 'base-btn__spinner'
          })
        : null
    ])
  }
}
</script>

<!-- Do not add scoped attribute: it will break styling of children -->
<style lang="scss">
$border-radius: $v3-main-border-radius;
$border: 0;
$title-font-size: 16px;
$brand-btn-padding: 8px 16px;

@function button-var-name($type, $state, $style, $breakpoint-key) {
  @return theme-var-name('button', $type, $style, $state, $breakpoint-key);
}

// If a state specific CSS variable does not exist, it should fallback to the value of the given variant
// Fallback priority example of font-size in "hover" site in outlines:
//    Check on Hover font-size in secondary, if it does not exist let's check on default outline font-size,
//    finally fallback to the default font-size:
//    --button-secondary-hover-font-size -> --button-secondary-default-font-size -> button-font-size
@function fallback-to-no-state-var($type, $state, $style, $defaultValue, $breakpoint-key) {
  @return responsive-fallback-to-no-variant-var(
    'button',
    $type,
    $style,
    $defaultValue,
    $state,
    $breakpoint-key,
    'default'
  );
}

@mixin brand-btn(
  $breakpoint-key,
  $type,
  $state,
  $default-background,
  $default-font-size: $title-font-size,
  $default-text-color: $v3-color-white,
  $default-border-radius: $border-radius,
  $default-border: 0,
  $default-box-shadow: none,
  $default-padding: $brand-btn-padding,
  $default-font-weight: none
) {
  // E.g. --button-primary-default-background or --button-secondary-focus-background
  background-color: var(
    button-var-name($type, $state, 'background', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'background', $default-background, $breakpoint-key)
  );
  font-size: var(
    button-var-name($type, $state, 'font-size', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'font-size', $default-font-size, $breakpoint-key)
  );
  color: var(
    button-var-name($type, $state, 'text-color', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'text-color', $default-text-color, $breakpoint-key)
  );
  border-radius: var(
    button-var-name($type, $state, 'border-radius', $breakpoint-key),
    fallback-to-no-state-var(
      $type,
      $state,
      'border-radius',
      $default-border-radius,
      $breakpoint-key
    )
  );
  border: var(
    button-var-name($type, $state, 'border', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'border', $default-border, $breakpoint-key)
  );
  box-shadow: var(
    button-var-name($type, $state, 'box-shadow', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'box-shadow', $default-box-shadow, $breakpoint-key)
  );
  font-weight: var(
    button-var-name($type, $state, 'font-weight', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'font-weight', $default-font-weight, $breakpoint-key)
  );
  padding: var(
    button-var-name($type, $state, 'padding', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'padding', $default-padding, $breakpoint-key)
  );
}

@mixin responsive-brand-btn($args...) {
  @each $breakpoint-key, $_ in $grid-breakpoints {
    @if $breakpoint-key != 'xs' {
      // Apply media query from sm and above (starting with @media (min-width: 576px))
      @include media-breakpoint-up(#{$breakpoint-key}) {
        @include brand-btn($breakpoint-key, $args...);
      }
    } @else {
      // Default, mobile first design @media (min-width: 0px)
      @include brand-btn('', $args...);
    }
  }
}

.base-btn {
  @include responsive-brand-btn('primary', 'default', $v3-primary);

  display: inline-flex;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &[type='button'],
  &[type='submit'],
  &[type='reset'] {
    appearance: none;
  }

  &:hover {
    @include responsive-brand-btn('primary', 'hover', $v3-color-cobalt);

    text-decoration: none;
  }

  &:focus {
    @include responsive-brand-btn(
      'primary',
      'focus',
      $v3-color-white,
      $title-font-size,
      $v3-primary,
      $border-radius,
      $v3-primary solid 1px
    );

    outline: 0;

    &:active {
      @include responsive-brand-btn('primary', 'active', $v3-color-cobalt);
    }
  }

  &[disabled],
  &--disabled {
    @include responsive-brand-btn('primary', 'disabled', $v3-color-hit-gray);

    cursor: not-allowed;
  }

  > span,
  > .icon-base {
    pointer-events: none;
  }

  &--secondary {
    @include responsive-brand-btn(
      'secondary',
      'default',
      transparent,
      $title-font-size,
      $v3-primary,
      $border-radius,
      $v3-primary solid 1px
    );

    outline: 0;

    &:hover {
      @include responsive-brand-btn(
        'secondary',
        'hover',
        $v3-color-blue-light,
        $title-font-size,
        $v3-primary,
        $border-radius,
        $v3-primary solid 1px
      );
    }

    &:focus {
      @include responsive-brand-btn(
        'secondary',
        'focus',
        $v3-primary,
        $title-font-size,
        $v3-color-white,
        $border-radius,
        $v3-primary solid 1px
      );
    }

    &:active {
      @include responsive-brand-btn(
        'secondary',
        'active',
        $v3-color-athens-gray,
        $title-font-size,
        $v3-primary,
        $border-radius,
        $v3-primary solid 1px
      );
    }

    &[disabled],
    &--disabled,
    &.base-btn--disabled {
      @include responsive-brand-btn(
        'secondary',
        'disabled',
        $v3-color-blue-light,
        $title-font-size,
        $v3-primary,
        $border-radius,
        $v3-primary solid 1px
      );
    }
  }

  &--danger {
    @include responsive-brand-btn(
      'danger',
      'default',
      $v3-color-red,
      $title-font-size,
      $v3-color-white,
      $border-radius,
      $v3-color-red solid 1px
    );

    outline: 0;

    &:hover {
      @include responsive-brand-btn(
        'danger',
        'hover',
        $v3-color-red-dark,
        $title-font-size,
        $v3-color-white,
        $border-radius,
        $v3-color-red-dark solid 1px
      );
    }

    &:focus {
      @include responsive-brand-btn(
        'danger',
        'focus',
        $v3-color-red-dark,
        $title-font-size,
        $v3-color-white,
        $border-radius,
        $v3-color-red-dark solid 1px
      );
    }

    &:active {
      @include responsive-brand-btn(
        'danger',
        'active',
        $v3-color-athens-gray,
        $title-font-size,
        $v3-primary,
        $border-radius,
        $v3-primary solid 1px
      );
    }

    &[disabled],
    &--disabled,
    &.base-btn--disabled {
      @include responsive-brand-btn(
        'danger',
        'disabled',
        $v3-color-blue-light,
        $title-font-size,
        $v3-primary,
        $border-radius,
        $v3-primary solid 1px
      );
    }
  }

  &--success {
    @include responsive-brand-btn(
      'success',
      'default',
      $v3-color-green,
      $title-font-size,
      $v3-color-white,
      $border-radius,
      $v3-color-green solid 1px
    );

    outline: 0;

    &:hover {
      @include responsive-brand-btn(
        'success',
        'hover',
        $v3-color-green-dark,
        $title-font-size,
        $v3-color-white,
        $border-radius,
        $v3-color-green-dark solid 1px
      );
    }

    &:focus {
      @include responsive-brand-btn(
        'success',
        'focus',
        $v3-color-green-dark,
        $title-font-size,
        $v3-color-white,
        $border-radius,
        $v3-color-green-dark solid 1px
      );
    }

    &:active {
      @include responsive-brand-btn(
        'success',
        'active',
        $v3-color-athens-gray,
        $title-font-size,
        $v3-primary,
        $border-radius,
        $v3-primary solid 1px
      );
    }

    &[disabled],
    &--disabled,
    &.base-btn--disabled {
      @include responsive-brand-btn(
        'success',
        'disabled',
        $v3-color-blue-light,
        $title-font-size,
        $v3-primary,
        $border-radius,
        $v3-primary solid 1px
      );
    }
  }

  &--icon-only {
    padding-right: 8px;
    padding-left: 8px;
  }

  &--squared {
    &,
    &:hover,
    &:active,
    &:focus,
    &[disabled],
    &--disabled,
    &.base-btn--disabled {
      border-radius: 0;
    }
  }

  .icon-base {
    fill: currentColor;
    flex-shrink: 0;

    &:only-child {
      margin: 0 auto;
    }
  }

  &--expand-on-small {
    @include media-breakpoint-down(md) {
      width: 100%;
      justify-content: center;
    }
  }

  &--shrink-on-small {
    @include media-breakpoint-down(md) {
      padding-right: 0;
      padding-left: 0;
      justify-content: center;

      .icon-base ~ * {
        display: none;
      }

      .icon-base,
      .icon-base:not(:only-child) {
        margin: 0;
      }
    }
  }

  &__spinner {
    margin-left: 8px;
  }
}
</style>
