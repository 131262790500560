// Background types:
// 'primary', 'top-ribbon-primary', 'top-ribbon-wb', 'blank'

// Layout types:
// 'boxes-wide', 'boxes-overlay-narrow',
// 'custom-legal', 'custom-faq', 'custom-error', 'custom-main-dashboard', custom-dashboard,
// 'custom-preresult', 'custom-kitreg- ',
// 'custom-profile', 'custom-order', 'custom-survey- ', 'custom-static-results',
// 'custom-prescreen-', 'custom-patient', 'custom-location', 'custom-scheduling',
// 'custom-direct', 'custom-direct-prescreen'

// Routes
import authenticationRoutes from './authentication.js'
import signedInPagesRoutes from './signedInPages.js'
import prescreenRoutes from './prescreen.js'
import surveyRoutes from './survey.js'
import dashboardRoutes from './dashboard.js'
import viewsRoutes from './views.js'
import { i18n } from '@/plugins/i18n.js'

// Main flow
const TheMain = () => import('@/components/views/Main/TheMain.vue')
const Home = () => import('@/components/views/HomeV3/HomeV3.vue')
const Legal = () => import('@/components/views/Main/LegalV3/LegalV3.vue')
const Direct = () => import('@/components/views/Main/Direct/Direct.vue')
const Prescreen = () => import('@/components/views/Main/Prescreen/PrescreenV3.vue')
const PrescreenConsent = () =>
  import('@/components/views/Main/PrescreenConsent/PrescreenConsentV3.vue')
const FAQ = () => import('@/components/views/Main/FAQ/FAQ.vue')
const Error = () => import('@/components/views/Error/Error.vue')
const GuardianConsent = () => import('@/components/views/GuardianConsent/GuardianConsent.vue')
const SignedOutResult = () => import('@/components/views/Results/views/SignedOutResult.vue')
const NotificationPreferences = () =>
  import('@/components/views/NotificationPreferences/NotificationPreferences.vue')

export default {
  path: '',
  component: TheMain,
  name: 'TheMain',
  children: [
    ...authenticationRoutes,
    ...signedInPagesRoutes,
    ...prescreenRoutes,
    ...viewsRoutes,
    { ...surveyRoutes },
    { ...dashboardRoutes },
    {
      path: 'home',
      name: 'Home',
      component: Home,
      meta: {
        title: i18n.t('headful_title.home'),
        bgType: 'primary',
        layoutType: 'boxes-wide',
        redirectOnAuthentication: true
      }
    },
    {
      path: 'legal/:productId?',
      name: 'Legal',
      component: Legal,
      props: true,
      meta: {
        title: i18n.t('headful_title.terms_and_conditions'),
        bgType: 'top-ribbon-primary',
        layoutType: 'custom-legal'
      }
    },
    {
      path: 'direct/:orgId/:productId',
      name: 'Direct',
      component: Direct,
      props: true,
      meta: {
        title: i18n.t('headful_title.direct'),
        bgType: 'blank',
        layoutType: 'custom-direct'
      }
    },
    {
      path: 'direct-prescreen/:accessCode/:orgId/:productId',
      name: 'DirectPrescreen',
      component: Prescreen,
      props: true,
      meta: {
        title: i18n.t('headful_title.prescreen'),
        isFooterHidden: true,
        showOrderWizardHeader: true,
        bgType: 'blank',
        layoutType: 'custom-direct-prescreen'
      }
    },
    {
      path: 'direct-prescreen-consent/:accessCode/:orgId/:productId',
      name: 'DirectPrescreenConsent',
      component: PrescreenConsent,
      props: true,
      meta: {
        title: i18n.t('headful_title.prescreen_consent'),
        bgType: 'blank',
        showOrderWizardHeader: true,
        isFooterHidden: true,
        layoutType: 'custom-direct-prescreen-consent'
      }
    },
    {
      path: 'faq',
      name: 'FAQ',
      component: FAQ,
      meta: {
        title: i18n.t('headful_title.faq'),
        bgType: 'top-ribbon-primary',
        layoutType: 'custom-faq'
      }
    },
    {
      path: 'error',
      name: 'Error',
      component: Error,
      meta: {
        title: i18n.t('headful_title.error'),
        bgType: 'top-ribbon-primary',
        layoutType: 'custom-error'
      }
    },
    {
      path: 'consent/:uuid',
      name: 'GuardianConsent',
      component: GuardianConsent,
      meta: {
        title: i18n.t('headful_title.guardian_consent'),
        bgType: 'blank',
        layoutType: 'custom-guardian-consent'
      }
    },
    {
      path: 'external-results/:uuid/',
      name: 'ExternalResults',
      component: SignedOutResult,
      props: true,
      meta: {
        title: i18n.t('headful_title.signed_out_result'),
        bgType: 'blank'
      }
    },
    {
      path: 'notification-preferences',
      name: 'NotificationPreferences',
      component: NotificationPreferences,
      meta: {
        title: i18n.t('headful_title.notification_preferences')
      }
    }
  ]
}
