import moment from 'moment'
import { isUndefined, keyBy } from 'lodash-es'
import { CONFIG } from '@/constants/config.js'
import { getConfig } from '@/use/useConfig.js'
import phoneCodes from '@/config/phone_codes.json'

export const getUpdateProfilePayload = (data) => {
  const dateOfBirth = moment([
    data.personal.dateOfBirth.year,
    Math.max(0, data.personal.dateOfBirth.month),
    data.personal.dateOfBirth.day
  ])

  let profileData = {
    first_name: data.personal.firstName,
    last_name: data.personal.lastName,
    gender: data.personal.gender,
    date_of_birth: dateOfBirth.format('YYYY-MM-DD'),
    email: data.personal.email,
    phone: uploadPhone(
      data.personal.phone,
      data.personal.countryIsoCode || getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)
    ),
    address: data.personal.address.address,
    address_2: data.personal.address.address2 || '',
    city: data.personal.address.city,
    state: data.personal.address.state,
    zip: data.personal.address.zip,
    timezone: data.personal.timezone
  }

  if (data.personal?.ethnicity?.length) {
    profileData.ethnicity = data.personal.ethnicity
  }

  if (data.personal?.race?.length) {
    profileData.race = data.personal.race
  }

  // guardian information
  if (Object.keys(data.guardian).length > 0) {
    const guardianDateOfBirth = moment([
      data.guardian.dateOfBirth.year,
      Math.max(0, data.guardian.dateOfBirth.month),
      data.guardian.dateOfBirth.day
    ])

    profileData = {
      ...profileData,
      guardian_first_name: data.guardian.firstName,
      guardian_last_name: data.guardian.lastName,
      guardian_email: data.guardian.email,
      guardian_phone: uploadPhone(
        data.guardian.phone,
        data.personal.country || getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)
      ),
      guardian_date_of_birth: guardianDateOfBirth.format('YYYY-MM-DD'),
      guardian_gender: data.guardian.gender,
      guardian_relationship: data.guardian.relationship,
      guardian_address: data.guardian.address.address,
      guardian_address_2: data.guardian.address.address2 || '',
      guardian_city: data.guardian.address.city,
      guardian_state: data.guardian.address.state,
      guardian_zip: data.guardian.address.zip
    }
  }

  return profileData
}

const countryCodeLookUpByIsoCode = keyBy(phoneCodes, 'iso_code')

export const displayPhone = (
  phone,
  countryIsoCode = getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)
) => {
  if (phone) {
    const isoCode = countryIsoCode.toUpperCase()
    const countryCode = countryCodeLookUpByIsoCode[isoCode].country_code
    const maskFormat = countryCodeLookUpByIsoCode[isoCode].mask_format

    const splittedFormat = maskFormat.split('-')
    const sliceOn1 = splittedFormat[0].toString().length
    const sliceOn2 = splittedFormat[1]
      ? splittedFormat[0].toString().length + splittedFormat[1].toString().length
      : ''

    if (splittedFormat.length > 2) {
      return (
        '+' +
        countryCode +
        ' ' +
        phone.slice(0, sliceOn1) +
        '-' +
        phone.slice(sliceOn1, sliceOn2) +
        '-' +
        phone.slice(sliceOn2)
      )
    } else if (splittedFormat.length === 2) {
      return '+' + countryCode + ' ' + phone.slice(0, sliceOn1) + '-' + phone.slice(sliceOn1)
    } else {
      return '+' + countryCode + ' ' + phone
    }
  }
  return 'N/A'
}

export const uploadPhone = (phone, countryIsoCode = getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)) => {
  if (phone) {
    const isoCode = countryIsoCode.toUpperCase()
    const countryCode = countryCodeLookUpByIsoCode[isoCode].country_code
    return '+' + countryCode + phone
  }

  return ''
}

export const getPhone = (phone, countryIsoCode = getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)) => {
  if (phone) {
    const isoCode = countryIsoCode.toUpperCase()
    const countryCode = countryCodeLookUpByIsoCode[isoCode].country_code
    const numOfChar = countryCode.toString().length
    return phone.slice(numOfChar + 1)
  }

  return ''
}

export const getPhoneMaskFormat = (countryIsoCode = getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)) => {
  if (countryIsoCode) {
    const isoCode = countryIsoCode.toUpperCase()
    return countryCodeLookUpByIsoCode[isoCode].mask_format
  }
}

export const getFullName = (user) => {
  if (!user) return ''

  const firstName = user.first_name || ''
  const lastName = user.last_name || ''
  return `${firstName} ${lastName}`.trim()
}

export const updateAgeModel = (dateOfBirth) => {
  if (!dateOfBirth) return null

  const { year, month, day } = dateOfBirth
  const dob = moment([year, month, day])
  return dob.isValid() ? moment().diff(dob, 'years') : null
}

export const updateDateOfBirthInstance = (dateOfBirth) => {
  if (!dateOfBirth) return null

  const { year, month, day } = dateOfBirth
  if (!(year && !isUndefined(month) && day)) {
    return null
  }

  return new Date(year, month, day)
}
