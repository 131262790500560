import { i18n } from '@/plugins/i18n.js'

// Survey flow
const Survey = () => import('@/components/views/Survey/Survey.vue')
const SurveyListPage = () => import('@/components/views/Survey/SurveyListPage/SurveyListPage.vue')
const SurveyPage = () => import('@/components/views/Survey/SurveyPage/SurveyPage.vue')

export default {
  path: 'surveys',
  component: Survey,
  children: [
    {
      path: '',
      name: 'SurveyListPage',
      component: SurveyListPage,
      meta: {
        title: i18n.t('headful_title.survey'),
        requiresAuth: true,
        bgType: 'primary',
        layoutType: 'custom-survey-list'
      }
    },
    {
      path: ':id',
      name: 'SurveyPage',
      component: SurveyPage,
      props: true,
      meta: {
        title: i18n.t('headful_title.survey'),
        requiresAuth: true,
        bgType: 'primary',
        layoutType: 'custom-survey'
      }
    }
  ]
}
